<template>
    <div class="collection">
      <div class="collection-title">
        <h2>{{ collectionTitle }}</h2>
      </div>
      <div v-if="screenContent.length === 0">
        <GridLayoutPlaceholder></GridLayoutPlaceholder>
      </div>
      <Grid :contents="screenContent" :playerInstance="playerInstance" :isScrollable="isScrollable" @loadMore="loadMoreContents()" v-else></Grid>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import { eventBus } from "@/eventBus";
  
  export default {
    props: {
      playerInstance: {
        type: Object,
      }
    },
    data() {
      return {
        loader: true,
        screenContent: [],
        errorMessage: null,
        localDisplayLang: null,
        page: 1,
        isScrollable: false,
        isMpegRequired: false,
        screen: false,
        allScreensList: [],
        collectionTitle: ''
      };
    },
    computed: {
      ...mapGetters(["appConfig"]),
    },
    watch: {
      $route() {
        this.screenContent = [];
        this.page = 1;
        this.getContentScreen();
      },
    },
    created() {
      // this.isMpegRequired = this.checkMpegRequired();
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      this.screenContent = [];
      this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
        
      //checking for change of language in local storage value
      eventBus.$on("check-for-series-route", (data) => {
        this.localDisplayLang = data;
        this.loader = true;
        this.screenContent = [];
        this.isScrollable = false;
        this.page = 1;
        // this.getContentScreen();
        this.getAllContents(this.isMpegRequired);
      });
  
    //   this.getContentScreen();
    //   this.getAllContents(this.isMpegRequired);
    },
    mounted() {
        this.collectionTitle = this.$route?.params?.section;
        this.collectionTitle = this.collectionTitle?.replaceAll('-', ' ');
        this.getContentScreen();
        eventBus.$on("get-seeAll-data", (response) => {
            // this.loader = true;
            if (!response.reason) {
            if (this.screenContent && this.screenContent.length > 0) {
                this.screenContent = [...this.screenContent, ...response.data];
    
            } else {
                this.screenContent = [...response.data];
            }
            //Set isScrollable flag to true.
            this.isScrollable = response.totalcount == this.screenContent.length ? false : true;
            this.loader = false;
            } else {
            this.loader = false;
            if (response.errorcode != 6066 && response.errorcode != 1001) this.errorMessage = response.reason;
            }
        });
    },
    methods: {
      checkMpegRequired() {
        let isMpegRequired = false;
        let videoTag = document.createElement("video");
        let platformOs = navigator.platform;
  
        if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
          if (platformOs.startsWith("Mac")) {
            isMpegRequired = true;
          }
        }
  
        return isMpegRequired;
      },
      getContentScreen() {
        let sectionId = this.$router.currentRoute.params.section.toLowerCase();
        let screenId = this.$router.currentRoute.params.slug.toLowerCase();
        if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled) {
            let deckingconfig = localStorage.getItem("appDeckingConfig") ? JSON.parse(localStorage.getItem("appDeckingConfig")) : '';
            this.allMenuList = [...deckingconfig.screens]
        } else {
            this.allMenuList = [...this.appConfig.screens, ...this.appConfig.moreMenuOptions]
        }

        let singleScreenData = this.allMenuList?.filter((ele) => {
            let screenTitle = ele.title.eng.replaceAll(' ','').toLowerCase();
            return (screenTitle)?.toLowerCase()?.includes(screenId);
        });
        singleScreenData && singleScreenData[0]?.sections?.forEach((item) => {
            let itemTitle = item?.title?.default?.replace(/[\s&/'/']+/g, "-");
            itemTitle = itemTitle?.toLowerCase();
            if(itemTitle?.includes(sectionId)) {
                this.screen = item;
            }
        });
        this.getAllContents(this.isMpegRequired);
      },
      getEndPoint () {
        const sectionType = this.screen.sectionType;
        const itemType = this.screen.itemType;
        
        if (sectionType == "ITEMLIST" && itemType == "CONTENT") {
            return "/subscriber/v1/content"
        } else if (sectionType == "ITEMLIST" && itemType == "TRAILERS") {
            return "/subscriber/v1/metadata/trailer"
        } else if (sectionType == "ITEMLIST" && itemType == "CASTNCREW") {
            return "/subscriber/v1/metadata/castncrew"
        }  else {
            return "/subscriber/v1/content"
        }
      },
  
      getAllContents(isMpegRequired) {
        let payload = {
          endpoint: this.screen.endpoint || this.getEndPoint(),
          params: this.screen.parameters || this.screen.sectionData || {}
        };
  
        if (this.screen.sectionType == "ITEMLIST") {
            let strItem = ''
             let item = this.screen.sectionData.map((data) => {
         
              return data.id;
             })
             strItem = strItem.substring(0, strItem.length - 1);
             if (this.screen.itemType == "CONTENT") {
              payload.params = {
                contentlist : JSON.stringify(item),
                orderby: {"objectid": item}
              }
             } else {
              payload.params = {
                contentlist : JSON.stringify(item)
              }
             }
           
          }
        payload.params.displaylanguage = this.localDisplayLang;
        payload.params.page = this.page;
  
        if (isMpegRequired == true) {
          payload.params.mpegtspackage = "YES";
        }  
        let subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");  
        if (subscriberProfileDetails) {
          subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
        }  
        if (subscriberProfileDetails && subscriberProfileDetails.kidsmode === "YES") {
          payload.params.genre = "Kids";
        }  
        eventBus.$emit("grid-see-all-data", payload);
      },
      initialRoute() {
        this.$router.push({ name: "Home" });
      },
      loadMoreContents() {
        // this.loader = true;
        this.page = this.page + 1;
        this.getAllContents(this.isMpegRequired);
      },
    },
    components: {
      noorPlayGrid: () => import(/* webpackChunkName: "categoriesPlaceholder" */ "@/components/Templates/noorPlayGrid.vue"),
      Grid: () => import(/* webpackChunkName: "Grid" */ "@/components/Templates/GridLayout.vue"),
      EptyBin: () => import(/* webpackChunkName: "eptyBin" */ "@/components/SvgImages/Empty.vue"),
      GridLayoutPlaceholder: () => import(/* webpackChunkName: "gridplaceholder" */ "@/components/placeholders/GridLayoutPlaceholder.vue"),
    },
    beforeDestroy() {
      this.screenContent = [];
      eventBus.$off("check-for-series-route");
      eventBus.$off("get-seeAll-data");
      
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@/sass/_variables.scss";
  @import "./collection.scss";
  
    .collection {
      width: clamp(2rem, 100%, 95%);
      margin: 4rem auto auto auto; 
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .collection-title {
        padding: 0.5rem 2rem;
        text-align: center;
        text-transform: uppercase;
        opacity: 0.7;
      }
      .gridMainLayout {
        padding-top: 0 !important;
      }
    }

    @media screen and (max-width:1000px) {
      .collection {
        margin-top: 8rem;
      }
    }
  </style>
  